import { Carousel as Base } from '@77sol-ui/molecules'
import { ClickableItem } from './ClickableItem'
import { useCarousel } from './hooks/useCarousel'

export function Carousel() {
  const { onClickFinancingBanner, onClickQuotationBanner, carouselConfigs } =
    useCarousel()

  return (
    <Base.Root {...carouselConfigs}>
      <ClickableItem onClick={onClickFinancingBanner}>
        <Base.Image
          mobileSrc="https://77solassets.s3.sa-east-1.amazonaws.com/banners/BannerFinanciamentoMobile.png"
          src="https://77solassets.s3.sa-east-1.amazonaws.com/banners/BannerFinanciamento.png"
        />
      </ClickableItem>
      <ClickableItem onClick={onClickQuotationBanner}>
        <Base.Image
          mobileSrc="https://77solassets.s3.sa-east-1.amazonaws.com/banners/BannerSelfcheckoutMobile.png"
          src="https://77solassets.s3.sa-east-1.amazonaws.com/banners/BannerSelfcheckout.png"
        />
      </ClickableItem>
      <Base.Item>
        <Base.Image
          mobileSrc="https://77solassets.s3.sa-east-1.amazonaws.com/banners/BannerSustentabilidadeMobile.png"
          src="https://77solassets.s3.sa-east-1.amazonaws.com/banners/BannerSustentabilidade.png"
        />
      </Base.Item>
    </Base.Root>
  )
}
