import { useAcl } from 'acl/hooks/useAcl'

const PARTICIPANT_CLOSERS = [
  'debora.machado@77sol.com.br',
  'larissa.rocha@77sol.com.br',
]

export function usePartialDelivery() {
  const { user } = useAcl()

  const isInternalUser = user[0]?.email.endsWith('@77sol.com.br')

  const isClientOfCloser = PARTICIPANT_CLOSERS.includes(user[0]?.closer)

  const showNewHomeScreen = isInternalUser || isClientOfCloser

  return { showNewHomeScreen }
}
