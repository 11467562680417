import { useCreateFinancingPerValueModalContext } from 'containers/Financing/PerValue/CreateFinancingPerValueModal/context/CreateFinancingPerValueModalContext'
import { useQuotationModalContext } from 'context/QuotationModalContext'
import { useAmplitude } from 'hooks/useAmplitude'
import useWindowSize from 'hooks/useWindowSize'
import { BannersTrackers } from 'services/tracker/events/Banners'
import { useSidebarDesktopStore } from 'store/modals/SidebarStore'
import { CAROUSEL_DEFAULT_CONFIGS } from '../constants'

export function useCarousel() {
  const { setFinancingPerValueModalOpen } =
    useCreateFinancingPerValueModalContext()
  const { logEvent } = useAmplitude()
  const { setIsQuotationModalOpen } = useQuotationModalContext()

  const { isOpen } = useSidebarDesktopStore()

  const { width } = useWindowSize()
  const isLargeScreen = width >= 1680
  const isDesktop = width >= 1280

  const centerPaddingDesktop = isOpen ? '35px' : '120px'
  const centerPaddingBanner = isDesktop ? centerPaddingDesktop : '20px'

  const carouselConfigs = {
    ...CAROUSEL_DEFAULT_CONFIGS,
    dots: isDesktop,
    slidesToShow: isLargeScreen ? 2 : 1,
    centerPadding: centerPaddingBanner,
  }

  function onClickFinancingBanner() {
    logEvent(BannersTrackers.actions.clickOnBanner, {
      origin: '/dashboard',
      banner: 'Financiamentos',
    })
    setFinancingPerValueModalOpen()
  }

  function onClickQuotationBanner() {
    logEvent(BannersTrackers.actions.clickOnBanner, {
      origin: '/dashboard',
      banner: 'Cotação',
    })
    setIsQuotationModalOpen(true)
  }

  return {
    onClickFinancingBanner,
    onClickQuotationBanner,
    carouselConfigs,
  }
}
